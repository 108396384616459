import { useEffect, useState } from 'react';

import { SignInForm } from '@scannable/web/forms';
import { TermsAndPrivacyFooter } from '@scannable/web/molecules';
import { GeneralLayout, getLastEmail, useTranslation } from '@scannable/web/ui';

import { serverSideAuth } from '../lib/utils';

function SignIn() {
  const { t } = useTranslation();
  const [lastEmail, setLastEmail] = useState<string | undefined>();
  useEffect(() => {
    try {
      const prevStoredEmail = getLastEmail();
      const lastEmail = prevStoredEmail ? prevStoredEmail : '';
      setLastEmail(lastEmail);
    } catch (error) {
      setLastEmail('');
    }
  }, []);
  return (
    <GeneralLayout>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              {t('sign_into_your_account')}
            </h2>
          </div>
          {lastEmail !== undefined && <SignInForm initialEmail={lastEmail} />}
        </div>
      </div>
      <TermsAndPrivacyFooter />
    </GeneralLayout>
  );
}
export const getServerSideProps = serverSideAuth;

export default SignIn;
